import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
// import Box from 'components/box';
// import Title from 'components/title';
// import Gallery from 'components/gallery';
// import IOExample from 'components/io-example';
import HeaderJumbotron from 'components/headerJumbotron';
import IconSection from 'components/iconSection';
import ZetaSection from 'components/zetaSection';
import RoomsBox from 'components/roomsBox';
import MapsSection from 'components/mapsSection';
import Footer from 'components/footer';
import { Container, Row, Col } from 'reactstrap';
// import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image';
import StickyBox from 'react-sticky-box';
import RoomsGuestsSelector from 'components/roomsGuestsSelector';
import axios from 'axios';

import { graphql } from 'gatsby';
class Occupancy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: {
        no: '501',
        name: 'Anil Punjabi',
        checkIn: '12/09',
        checkOut: '13/09',
        Tariff: '4500',
        Source: 'Direct',
      },
    };
  }

  componentDidMount() {
    var signinUrl = 'https://beta.djubo.com/sign-in/';
    console.log(signinUrl);
    var reqData = {
      email_address: 'hotelgracegalaxy@gmail.com',
      password: 'galaxy@8822',
    };
    // axios({
    //     method: 'post',
    //     url: signinUrl,
    //     crossdomain: true,
    //     maxRedirects: 0,
    //     data: $.param(reqData),
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded"
    //     },
    //     // jar: cookieJar, // tough.CookieJar or boolean
    //     withCredentials: true, // If true, send cookie stored in jar
    //   })
    //   .then((response) => {
    //     console.log(response)
    //   });

    fetch(signinUrl, {
      mode: 'cors',
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: {
        email_address: 'hotelgracegalaxy@gmail.com',
        password: 'galaxy@8822',
      },
    });
  }

  render() {
    return (
      <Layout>
        <Container>
          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>{this.state.rooms.no}</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>d</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>d</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>

          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>d</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>d</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>d</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>d</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>d</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
            <Col
              lg={{ size: 4 }}
              style={{
                marginTop: '20px',
                height: '200px',
                borderColor: '#ddd',
                border: '1px solid',
              }}
            >
              <p>asd</p>
            </Col>
          </Row>
        </Container>

        {/* <Footer /> */}
      </Layout>
    );
  }
}

export default Occupancy;
